import Vue from 'vue'

export  function  handleOrderStatus ({status, collect}) {
    // 订单状态 0-待付款，1-待制作，2-制作完成，待发货，3-已发货，4-退货，5-取消订单 6-已取货
    let text = ''

    if (typeof status !== 'undefined') {
        if (collect === 0) {
            text = Vue.prototype.$order_status_list1.find(i =>i.value === status)?.label
        } else if (collect === 1) {
            text = Vue.prototype.$order_status_list2.find(i =>i.value === status)?.label
        }

    }

    return text
}
export function handleOrderDownload (order) {
    if (order.media && order.media.length > 0) {
        order.media.forEach(item => {
            let url =  item.url
            window.open(url, '_blank')
        })
    }
}
export function getOrderCode (order) {
    let a = order.id.split('-')
    return a[a.length - 1]
}
