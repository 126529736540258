<template>
	<div class="shop-aside">
		<el-menu
			:default-active="defaultActive"
			class="shop-menu"
			@select="handleSelect"
		>
<!--			<el-submenu index="1">-->
<!--				<template slot="title">-->
<!--					<i class="el-icon-location"></i>-->
<!--					<span>导航一</span>-->
<!--				</template>-->
<!--				<el-menu-item-group>-->
<!--					<template slot="title">分组一</template>-->
<!--					<el-menu-item index="1-1">选项1</el-menu-item>-->
<!--					<el-menu-item index="1-2">选项2</el-menu-item>-->
<!--				</el-menu-item-group>-->
<!--				<el-menu-item-group title="分组2">-->
<!--					<el-menu-item index="1-3">选项3</el-menu-item>-->
<!--				</el-menu-item-group>-->
<!--				<el-submenu index="1-4">-->
<!--					<template slot="title">选项4</template>-->
<!--					<el-menu-item index="1-4-1">选项1</el-menu-item>-->
<!--				</el-submenu>-->
<!--			</el-submenu>-->
			<el-menu-item index="orderOffline">
<!--				<i class="el-icon-menu"></i>-->
				<span slot="title">店内自取订单</span>
			</el-menu-item>
			<el-menu-item index="orderOnline">
<!--				<i class="el-icon-document"></i>-->
				<span slot="title">邮寄订单</span>
			</el-menu-item>
		</el-menu>
	</div>
</template>

<script>
export default {
	name: "layout-aside",
	data() {
		return {
			defaultActive: '',
			titleList: [

			],
			titleChecked: '',
		}
	},
	watch: {
		'$route.path' (val) {
			this.setDefaultPath()
		}
	},
	mounted() {
		console.log('shop aside mounted', this.$route)
		this.setDefaultPath()
	},
	methods: {
		setDefaultPath() {
			const {path, name} = this.$route
			console.log('setDefaultPath', name)
			this.defaultActive = name

		},
		handleSelect (path) {
			console.log(path)
			if (path) {
				this.$router.push({name: path})
			}
		},
		linkOtherWeb(url) {
			window.open(url)
		}
	}
}
</script>

<style lang="less">

.shop-aside {
	background: #fff;

	.shop-menu {
		width: 220px;
	}

	.title, .small-title {
		text-indent: 16px;
	}

	.small-title {
		font-size: 16px;
		color: #9DA3AE;
		height: 22px;
		line-height: 22px;
		margin-top: 20px;
		margin-bottom: 12px;
	}

	.title {
		height: 60px;
		margin-top: 8px;
		border-radius: 12px;
		color: #333333;
		font-size: 18px;
		line-height: 36px;
		display: flex;
		align-items: center;
		cursor: pointer;
		position: relative;
	}

	.title.checked, .title:hover {
		background: #F4F5F9;
	}

	.title.checked {
		> b {
			position: absolute;
			left: -19px;
			width: 20px;
			height: 70px;
			background: url("../../assets/image/menuchecked.png") no-repeat right center;
			background-size: 161px;
			animation: fadeInOut 0.5s;
		}

		> i {
			color: #FF661E;
		}
	}

	@keyframes fadeInOut {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}

	.line {
		background: #F3F4F6;
		height: 1px;
		width: 100%;
		margin-top: 8px;

	}


	.other-link {
		display: flex;
		justify-content: center;
		margin-top: 16px;

		.link-item {
			width: 70px;
			height: 32px;
			border: 1px dashed #D8DAE8;
			border-radius: 6px;
			text-align: center;
			line-height: 32px;
			margin: 0 12px;
			color: #666666;
			cursor: pointer;
		}

		.bilibili {
			background: url("../../assets/image/bilibili.png") no-repeat 2px center;
			text-indent: 14px;
		}

		.douyin {
			background: url("../../assets/image/douyin.png") no-repeat 4px center;
			text-indent: 14px;
		}
	}

	.aside-container {
		min-height: 100%;
		padding-top: 0.1px;
	}

	.icp {
		font-size: 12px;
		margin: 0 -15px -100px;
		height: 100px;
		line-height: 20px;
		color: #9DA3AE;
		text-indent: 16px;
		text-align: left;
		padding-top: 12px;

		> p {
		}
	}

}



</style>
