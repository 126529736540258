<template>
	<div class="login">
		<Header/>

		<div class="login-container">
			<div class="title">
				<div class="logo"><img src="../assets/image/wanxianglogo2.png" alt=""></div>
			</div>
			<el-form v-show="loginType === '1'" ref="form" label-width="80px" :model="form" :rules="rules"
					 :label-position="'top'">
				<el-form-item label="手机号:" prop="phone">
					<el-input v-model="form.phone">
						<template slot="prepend">+86</template>
					</el-input>
				</el-form-item>
				<el-form-item label="验证码:" prop="code">
					<el-input v-model="form.code">
						<template slot="append">
							<span class="code-button" v-show="codeStatus === 1" @click="handleSendCode('login_phone')">发送验证码</span>
							<span class="code-button" v-show="codeStatus === 2">{{ codeSeconds }}</span>
						</template>
					</el-input>
				</el-form-item>

			</el-form>
			<el-form v-show="loginType === '2'" ref="form2" label-width="80px" :model="form2" :rules="rules2"
					 :label-position="'top'">
				<el-form-item label="用户名:" prop="username">
					<el-input v-model="form2.username">
					</el-input>
				</el-form-item>
				<el-form-item label="密码:" prop="password">
					<el-input v-model="form2.password" type="password" show-password>
					</el-input>
				</el-form-item>

			</el-form>

			<div v-show="loginType === '3' && !notRegister" class="wechat-qrcode">

				<img v-show="!!wechatQrcode" :src="wechatQrcode" />

			</div>

			<div v-show="loginType === '3' && notRegister" style="color: #999;text-indent: 2em;line-height: 28px;font-size: 12px;">
				应国家法律要求，登录万象AI账号前需要验证手机号。
			</div>
			<el-form v-if="loginType === '3' && notRegister" ref="form3" label-width="80px" :model="form3" :rules="rules" :label-position="'left'">
				<el-form-item label="手机号:" prop="phone">
					<el-input v-model="form3.phone" >
						<template slot="prepend">+86</template>
					</el-input>
				</el-form-item>
				<el-form-item label="验证码:" prop="code">
					<el-input v-model="form3.code">
						<template slot="append">
							<span class="code-button" v-show="codeStatus === 1" @click="handleSendCode2('registry')">发送验证码</span>
							<span class="code-button" v-show="codeStatus === 2" >{{ codeSeconds }}</span>
						</template>
					</el-input>
				</el-form-item>

			</el-form>


			<div class="tip" v-show="loginType !== '3' || notRegister">
				<el-checkbox v-model="firstIsRegister"/>
				<span>
					未注册的手机号将自动注册。勾选即代表您同意并接受 <span class="clicked-span">服务协议</span> 与 <span
					class="clicked-span">隐私政策</span>
				</span>
			</div>

			<div class="submit" v-show="loginType !== '3' || notRegister">
				<el-button v-if="notRegister && loginType === '3'" :loading="loading" type="primary" @click="handleSubmit2">注册</el-button>
				<el-button v-else :loading="loading" type="primary" @click="handleSubmit">登录</el-button>
			</div>

			<div class="login-type">
				<div class="left">
					<span v-show="loginType !== '2'" @click="changeLoginType('2')"> 用户名密码登录 </span>
					<span v-show="loginType !== '1'" @click="changeLoginType('1')"> 手机验证码登录 </span>
					<span v-show="loginType !== '3'" @click="changeLoginType('3')"> 微信扫码登录 </span></div>
				<div class="right">
					<span @click="toRegister()"> 注册 </span>
					<span @click="toForget()"> 忘记密码 </span>
				</div>
			</div>

			<div class="icp">
				<p>{{ $18n.company_name }}</p>
				<p>{{ $18n.icp }}</p>
			</div>
		</div>
	</div>
</template>

<script>
import Header from "@/view/layout/Header";
import storage from "@/util/storage";

export default {
	name: "user-manage",
	components: {Header},
	data() {
		return {
			loading: false,
			loginType: '2', // 1手机验证码登录  2用户名密码登录
			form: {
				phone: '',
				code: ''
			},
			form2: {
				username: '',
				password: '',
			},

			form3: {
				phone: '',
				code: ''
			},
			wechatQrcode: '',
			wechatQrcodeTimeout: null,
			notRegister: false,
			openid: '',

			rules: {
				phone: [
					{required: true, message: '请输入手机号', trigger: 'blur'},
					{pattern: /^1[3-9]\d{9}$/, message: '输入的手机号不正确', trigger: 'blur'}
				],
				code: [
					{required: true, message: '请输入验证码', trigger: 'blur'},
				]
			},
			rules2: {
				username: [
					{required: true, message: '请输入用户名', trigger: 'blur'},
				],
				password: [
					{required: true, message: '请输入密码', trigger: 'blur'},
				]
			},
			codeStatus: 1, // 1未发送 2发送中
			codeSeconds: 60,
			firstIsRegister: true,

		}
	},
	methods: {
		handleSendCode(biz) {

			this.$refs.form.validateField(['phone'], (error) => {
				if (error) {
					return
				}
				this.$http.post('/smcode/send', {mobile: this.form.phone, biz: 'login_phone'})
					.then(res => {
						if (res.code === 200) {
							this.codeStatus = 2
							this.codeSeconds = 60
							this.handleSeconds()
						} else {

						}
					})
			})

		},
		handleSendCode2(biz) {

			this.$refs.form3.validateField(['phone'], (error) => {
				if (error) {
					return
				}
				this.$http.post('/smcode/send', {mobile: this.form3.phone, biz: 'registry'})
					.then(res => {
						if (res.code === 200) {
							this.codeStatus = 2
							this.codeSeconds = 60
							this.handleSeconds()
						} else {
						}
					})
			})

		},
		handleSeconds() {
			if (this.codeSeconds === 0) {
				this.codeStatus = 1
			} else {
				setTimeout(() => {
					this.codeSeconds--
					this.handleSeconds()
				}, 1000)
			}
		},
		handleSubmit2 () {
			this.$refs.form3.validate(valid =>{
				if (!valid) {
					return
				}
				let data  = {
					code:this.form3.code,
					openid: this.openid,
					phone: this.form3.phone
				}

				let url = `/wechat/oa/registry?code=${this.form3.code}&openid=${this.openid}&phone=${this.form3.phone}`

				this.$http.get(url)
				.then(res => {
					if (res.success) {
						this.handleLoginSuccess(res)
					}
				})

			})
		},
		handleSubmit() {
			if (this.loginType === '1') {// 手机验证码登录
				this.loading = true

				this.$http.post('/login_by_phone', {phone: this.form.phone, login_code: this.form.code})
				.then(res => {
					if (res.success) {
						this.handleLoginSuccess(res)

					}
				})
			} else if (this.loginType === '2') { // 用户名密码登录

				this.$refs.form2.validate(valid => {
					const {username, password} = this.form2
					let data = {
						username,
						password,
						captcha: '123'
					}
					this.loading = true
					this.$http.post('/login?next=123', data).then(res => {
						if (res.success) {
							this.handleLoginSuccess(res)
						}
					})
						.finally(() => {
							this.loading = false
						})
				})
			}

			// this.$refs.form.validate(valid => {
			// 	if (!valid) return
			// 	this.$router.push('/')
			// })
		},


		handleLoginSuccess (res) {
			this.$message.success('登录成功')
			storage.set('token', res.data.token)
			if (this.$route.query.redirect) {
				this.$router.push(this.$route.query.redirect)
			} else {
				this.$router.push('/')
			}
			this.$store.dispatch('getUserInfo')
		},

		changeLoginType(type) {
			this.loginType = type
			this.wechatQrcode = ''
			this.notRegister = false
			this.codeSeconds = 0
			this.codeStatus = 1
			this.wechatQrcodeTimeout && clearTimeout(this.wechatQrcodeTimeout)
			if (type === '1') {
				this.$refs.form.resetFields()
			} else if (type === '2') {
				this.$refs.form2.resetFields()
			} else if (type === '3') {
				this.getWechatWQRCode()
			}
		},
		toRegister() {
			this.$router.push({name: 'register'})
		},
		toForget() {
			this.$router.push({name: 'forget'})
		},

		getWechatWQRCode () {
			this.$http.get('/wechat/oa/login/qrcode')
			.then(res => {
				if (res.code ===200) {
					this.wechatQrcode = 'https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=' + res.data.ticket
					this.ticket = res.data.ticket
					this.wechatQrcodeTimeout = setTimeout(() => {
						this.checkWechatLogin()
					}, 3000)
				}
			})
		},
		// /wechat/oa/login/check
		checkWechatLogin () {
			if (!this.ticket) {
				return
			}
			this.$http.get('/wechat/oa/login/check?ticket=' + this.ticket)
			.then(res => {
				if (res.code === 200) {
					this.wechatQrcodeTimeout && clearTimeout(this.wechatQrcodeTimeout)
					this.handleLoginSuccess(res)
				} else if (res.code === 1008) {
					this.wechatQrcodeTimeout = setTimeout(() => {
						this.checkWechatLogin()
					}, 3000)
				} else if (res.code === 1002) {
					this.notRegister = true
					this.wechatQrcodeTimeout && clearTimeout(this.wechatQrcodeTimeout)
					this.openid = res.data.openid
				} else if (res.code === 1007) {
					this.$message.warning('二维码已过期')
					this.wechatQrcodeTimeout && clearTimeout(this.wechatQrcodeTimeout)
				}
			})
		},


	}
}
</script>

<style lang="less">
@import "../assets/css/user.less";



</style>
