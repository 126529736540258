
// 订单状态 0-待付款，1-待制作，2-制作完成，待发货，3-已发货，4-退货，5-取消订单

export const order_status_list_1 = [
    {value: 0, label: '待付款'},
    {value: 1, label: '制作中'},
    {value: 2, label: '待取货'},
    {value: 3, label: '已取货'},
    {value: 4, label: '退货'},
    {value: 5, label: '取消'},
    {value: 7, label: '超时未支付'},
]
export const order_status_list_2 = [
    {value: 0, label: '待付款'},
    {value: 1, label: '制作中'},
    {value: 2, label: '待发货'},
    {value: 3, label: '已发货'},
    {value: 6, label: '已收货'},
    {value: 4, label: '退货'},
    {value: 5, label: '取消'},
    {value: 7, label: '超时未支付'},
]
