<template>
	<div class="page-app  page-common ">
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<div class="breadcrumb">
				<div class="breadcrumb-item" >用户管理</div>
			</div>
		</el-breadcrumb>
		<div class="page-container">

			<div class="page-opts">
				<div class="left">

					<el-form ref="resetPass" inline label-width="100px" >
<!--						<el-form-item  label="用户名称">-->
<!--							<el-input clearable size="small" placeholder="输入用户名称" v-model.trim="form.appName"/>-->
<!--						</el-form-item>-->

						<el-form-item  >
							<el-button type="primary" size="small" @click="getData">查询</el-button>
						</el-form-item>
					</el-form>


				</div>
				<div class="right"></div>
			</div>

			<div class="page-table">
				<el-table :data="tableData"
						  :row-key="row => row.id"
						  v-loading="loading"
						  border
						  size="small"
						  ref="table">

					<el-table-column  label="用户id" prop="id" align="center"></el-table-column>
					<el-table-column  label="用户名称" prop="username" align="center"></el-table-column>
<!--					<el-table-column  label="邀请码" prop="registry_code" align="center"></el-table-column>-->
					<el-table-column  label="手机号" prop="phone" align="center"></el-table-column>
					<el-table-column  label="邮箱" prop="email" align="center"></el-table-column>
					<el-table-column label="操作" prop="action" align="center" width="150px">
						<template slot-scope="{row}">
							<div class="file-table-action-list" v-if="row.collect===0">
								<el-button size="small" type="text"  @click="handleIncreasePoint(row, 1)">增加积分</el-button>
								<!--								<el-button size="small" type="text" :disabled="row.status !== 1" @click="handleUpdateStatus(row, 2)">通知取件</el-button>-->
								<!--								<el-button size="small" type="text" :disabled="row.status !== 2" @click="handleUpdateStatus(row, 3)" >已取货</el-button>-->
							</div>

						</template>
					</el-table-column>
				</el-table>
			</div>

		</div>


		<el-pagination
			background
			layout="prev, pager, next, total"
			:current-page="current"
			:page-size="size"
			@current-change="handleCurrentChange"
			:total="total">
		</el-pagination>

	</div>
</template>

<script>
/**
 创建时间：2025/1/11 8:24
 创建人：fxl
 */


/**
 * 			"avatar": null,
 *         "create_at": "2024-11-28 16:13:45",
 *         "email": "gylixx7@163.com",
 *         "id": 19,
 *         "nickname": "ceshi07",
 *         "phone": "15656805102",
 *         "registry_code": "7CuZ0p",
 *         "role": 1,
 *         "username": "ceshi07"
 * **/

import {handleAmount} from "@/util";
import {handleOrderStatus, handleOrderDownload, getOrderCode} from "@/util/order";
import moment from "moment";

export default {
	name: "",
	components: {},
	data() {
		return {

			form: {
				appName: '',
			},
			tableData: [],
			loading: false,
			current: 1,
			size: 10,
			total: 0,
			order_date: [],
			pickerOptions: {
				shortcuts: [
					{
						text: '今天',
						onClick(picker) {
							const end = new Date();
							picker.$emit('pick', [end, end]);
						}
					},
					{
						text: '昨天',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24);
							picker.$emit('pick', [start, start]);
						}
					},{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 29);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近三个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 89);
							picker.$emit('pick', [start, end]);
						}
					}]
			},
		}
	},
	watch: {

	},
	computed: {
	},
	mounted() {
		this.getData()
	},
	methods: {
		moment,
		getData() {

			this.$http.get(`/user/admin/list?page=${this.current}&limit=${this.size}`)
				.then(res => {
					if (res.code === 200) {
						this.tableData = res.data.data
						this.total = res.data.count
					}
				})


		},
		handleCurrentChange(current) {
			this.current = current;
			this.getData();
		},
		handleIncreasePoint () {

		}
	}
}
</script>

<style scoped lang="less">
@import "../../assets/css/page-table-common.less";


</style>
