<template>
	<div class="page-app  page-common ">
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<div class="breadcrumb">
				<div class="breadcrumb-item" >APP管理</div>
			</div>
		</el-breadcrumb>
		<div class="page-container">

			<div class="page-opts">
				<div class="left">

					<el-form ref="resetPass" inline label-width="100px" >
						<el-form-item  label="APP名称">
							<el-input clearable size="small" placeholder="输入APP名称" v-model.trim="form.appName"/>
						</el-form-item>

						<el-form-item  >
							<el-button type="primary" size="small" @click="getData">查询</el-button>
						</el-form-item>
					</el-form>


				</div>
				<div class="right"></div>
			</div>

			<div class="page-table">
				<el-table :data="tableData"
						  :row-key="row => row.id"
						  v-loading="loading"
						  border
						  size="small"
						  ref="table">

					<el-table-column width="200px" label="APP名称" prop="appName" align="center">
						<template slot-scope="scope">
							<el-button size="small" type="text" ></el-button>
						</template>
					</el-table-column>

					<el-table-column label="操作" prop="action" align="center" width="150px">
						<template slot-scope="{row}">
							<div class="file-table-action-list" v-if="row.collect===0">
<!--								<el-button size="small" type="text" :disabled="row.status !== 0" @click="handleUpdateStatus(row, 1)">确认付款</el-button>-->
<!--								<el-button size="small" type="text" :disabled="row.status !== 1" @click="handleUpdateStatus(row, 2)">通知取件</el-button>-->
<!--								<el-button size="small" type="text" :disabled="row.status !== 2" @click="handleUpdateStatus(row, 3)" >已取货</el-button>-->
							</div>

						</template>
					</el-table-column>
				</el-table>
			</div>

		</div>


		<el-pagination
			background
			layout="prev, pager, next, total"
			:current-page="current"
			:page-size="size"
			@current-change="handleCurrentChange"
			:total="total">
		</el-pagination>

	</div>
</template>

<script>
/**
 创建时间：2025/1/11 8:24
 创建人：fxl
 */


import {handleAmount} from "@/util";
import {handleOrderStatus, handleOrderDownload, getOrderCode} from "@/util/order";
import moment from "moment";

export default {
	name: "",
	components: {},
	data() {
		return {

			form: {
				appName: '',
			},
			tableData: [],
			loading: false,
			current: 1,
			size: 10,
			total: 0,
			order_date: [],
			pickerOptions: {
				shortcuts: [
					{
						text: '今天',
						onClick(picker) {
							const end = new Date();
							picker.$emit('pick', [end, end]);
						}
					},
					{
						text: '昨天',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24);
							picker.$emit('pick', [start, start]);
						}
					},{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 29);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近三个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 89);
							picker.$emit('pick', [start, end]);
						}
					}]
			},
		}
	},
	watch: {

	},
	computed: {
		title () {
			return this.type === 2 ? '快递订单' : '店内定制订单'
		},
		statusList () {
			return this.type === 2 ? this.$order_status_list2 : this.$order_status_list1
		}
	},
	mounted() {
		this.type = this.$route.params.type * 1
		this.getData()
	},
	methods: {
		moment,
		handleAmount,
		handleOrderStatus,
		handleOrderDownload,
		getOrderCode,
		getData() {
			// /cluster/pageList
			let data = {
				"pageSize": this.size,
				"pageNum": this.current,
				collect: this.type - 1,
				shopId: 0
			}

			if (this.form.orderId) {
				data.oid = this.form.orderId
			}
			if (this.form.status) {
				data.status = this.form.status
			}

			if (this.order_date && this.order_date.length === 2) {
				data.start = moment(this.order_date[0]).format('YYYY-MM-DD') + ' 00:00:00'
				data.end = moment(this.order_date[1]).format('YYYY-MM-DD') + ' 23:59:59'
			}




			// this.$http.post('/biz/order/list', data)
			// 	.then(res => {
			// 		if (res.code === 200) {
			// 			this.tableData = res.data.records
			// 			this.total = res.data.total
			// 		}
			// 	})


		},
		handleUpdateStatus (order, status) {

			let message = ''

			if (!order.payPlatId && status === 1) {
				message = '确认线下已经付款？'
			} else if (order.collect === 0 && status === 2) {
				message = '确认制作完成，通知用户取件？'
			} else if (order.collect === 0 && status === 3) {
				message = '确认用户已经取货？'
			} else if (order.collect === 1 && status === 2) {
				message = '确认制作完成？'
			} else if (order.collect === 1 && status === 3) {
				message = '确认已发货？'
			} else if (order.collect === 1 && status === 6) {
				message = '确认用户已经收货？'
			}

			this.$confirm(message, {
				confirmButtonText: "确认",
				type: "warning",
				showClose: false,
				closeOnClickModal: false
			}).then(res => {
				let data = {
					"orderId": order.id,
					status,
					"remark": ""
				}
				this.$http.post('/biz/order/status', data)
					.then(res => {
						if (res.code === 200) {
							this.$message.success('提交成功')
							this.getData()
						}
					})
			})
		},
		handleCurrentChange(current) {
			this.current = current;
			this.getData();
		},
		handleNavigate (type) {
			this.$router.push('/order/' + type)
		}
	}
}
</script>

<style scoped lang="less">
@import "../../assets/css/page-table-common.less";

</style>
