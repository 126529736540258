import axios from "axios";
import {Message} from "element-ui";
import storage from "@/util/storage";


const serves = axios.create({
    baseURL: '/api/v1',
    timeout: 180000,
})

serves.interceptors.request.use(config => {

    config.headers.token = storage.get('token') || ''
    return config
})


const isIgnoreApi = (config) => {

    const list = [
        '/gradioapps.json',
        '/user/profile',
        '/file/list',
        '/shops/own',
        '/wechat/oa/login/check'
    ]
    for (let i = 0; i < list.length; i++) {
        if (config.url.indexOf(list[i]) > -1) {
            return true
        }
    }
    return false
}

serves.interceptors.response.use(res => {
    if (res.config.baseURL.indexOf('openapi-hk') > -1) {
        return res.data
    }
    if (res.status !== 200) {
        console.log(res)
        // 错误处理

    } else {
        if (!res.data.code || (res.data.code !== 200 && res.data.code !== 0)) {
            !isIgnoreApi(res.config) && Message.warning(res.data.msg)
        }
    }
    return res.data
}, error => {

    // 如果请求出错，抛出错误信息
    Message.warning(error.response?.data?.message || '系统错误')
    try {
        if (error.response) {
            console.error('服务器响应异常', error.response);
        } else if (error.request) {
            console.error('请求被发送，但没有收到响应', error.request);
        } else {
            console.error('请求设置时发生错误', error.message);
        }
    } catch (e) {

    }

    return Promise.reject(error);
})


export const uploadFile = (file, name = '') => {
    if (!file) {
        return
    }
    return new Promise((resolve, reject) => {
        const formData = new FormData();
        if (name) {
            formData.append('file', file, name);
        } else {
            formData.append('file', file);
        }

        serves.post('/file/upload', formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
            .then(response => {
                if (response.code === 200) {
                    resolve({url: response.data.href, id: response.data.id})
                }
            })
            .catch(error => {
                reject(error)
            });
    })
}

export const getFileCategory = () => {
    return serves.get('/file/category')
}

export const openaiHKRequest = (url,  params = {}, config = {}) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer hk-324oex10000435918852f542acd50db1c2a9a3164954367c'
    };
    const data = {
       ...params
    }

    if (config.method === 'get') {
        return  serves.request({
            method: 'get',
            baseURL: '/openapi-hk',
            headers,
            url
        })
    } else {
        return serves.request({
            method: config.method || 'post',
            data,
            baseURL: '/openapi-hk',
            headers,
            url
        })
    }



}


export default serves
